import globals from "../globals.js"
export default {
    data() {
        return {
            warning_msg: "",
        }
    },
    methods:{
        // Organization apis
        getOrganizations(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/organization", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        getHierarchyListView(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/organization/companies/hierarchy", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        getOrganizationById(param) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + "/organization/" + param)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        // createOrganization(params) {
        //     return new Promise(async (resolve, reject) => {
        //         let response = await this.$http.post(globals.AUTH_SERVICE + "/organization/", params)
        //         console.log(response);
        //         if (response.data.status_id == 1) {
        //             resolve(response.data)
        //         } else {
        //             reject(response.data.reason)
        //         }
        //     })
        // },
        // editOrganization(params, id) {
        //     return new Promise(async (resolve, reject) => {
        //         let response = await this.$http.patch(globals.AUTH_SERVICE + `/organization/${id}`, params)
        //         if (response.data.status_id == 1) {
        //             resolve(response.data)
        //         } else {
        //             reject(response.data.reason)
        //         }
        //     })
        // },
        deleteOrganization(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.delete(globals.AUTH_SERVICE + "/organization/delete", {
                        data: params
                    })
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        /*allow subsidiary toggle APi*/
        allowSubsidiaryToggle(params,id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.patch(globals.AUTH_SERVICE + `/organization/subsidiary/settings/${id}`,params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        subsidiaryProductList(params,id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/organization/subsidiary/settings/${id}?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        subsidiaryProductSubModules(params,id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/organization/subsidiary/settings/${id}?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}&product_id=${params.product_id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        }
    }
}
